import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Reeves, C.V., 1972.`}</strong>{` Rifting in the Kalahari? Nature, London, vol. 237, pp 95-96.`}</p>
    <p><strong parentName="p">{`Reeves, C.V. and Hutchins, D.G., 1976`}</strong>{`. The National Gravity Survey of Botswana 1972-1973, Bulletin No.5, Botswana Geological Survey.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 1978.`}</strong>{` A failed Gondwana spreading-axis in southern Africa, Nature, London, vol. 273, pp 222-223.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 1978.`}</strong>{` Reconnaissance Aeromagnetic Survey of Botswana, 1975-1977, Final Interpretation Report, Special Publication of the Botswana Geological Survey and the Canadian International Development Agency, 199 pp + appendices.`}</p>
    <p><strong parentName="p">{`Reeves, C.V. and MacLeod, I.N., 1983.`}</strong>{` Modelling of potential field anomalies - some applications for the micro-computer, First Break, vol. 1 no. 8, pp 18-24.`}</p>
    <p><strong parentName="p">{`Paterson, N.R., and Reeves, C.V., 1985.`}</strong>{` Applications of gravity and magnetic surveys - The state-of-the-art in 1985, Geophysics vol. 50, pp 2558-2594.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 1985.`}</strong>{` The Kalahari Desert, central southern Africa - a case history of regional gravity and magnetic exploration in W.J. Hinze (ed), The utility of gravity and magnetic surveys, Society of Exploration Geophysicists special volume, pp 144-156.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., Karanja, F.M. and MacLeod, I.N., 1987.`}</strong>{` Geophysical evidence for a failed Jurassic rift and triple junction in Kenya.  Earth and Planetary Science Letters vol. 81, pp 299-311.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 1989.`}</strong>{` Aeromagnetic interpretation and rock magnetism. First Break Vol. 7, No. 7, pp 275-286.`}</p>
    <p><strong parentName="p">{`Reeves,C.V., 1992.`}</strong>{` New horizons for airborne geophysical mapping. Exploration Geophysics, vol. 23, pp 273-280. (Reprinted ITC Journal 1993-2, pp 149-155).`}</p>
    <p><strong parentName="p">{`D.M.Finlayson, B. Finlayson, Reeves, C.V., P.R.Milligan, C.D.Cockshell, D.W.Johnstone and M.P.Morse, 1994.`}</strong>{` The Western Otway Basin - a tectonic framework from new seismic, gravity and aeromagnetic data. Exploration Geophysics vol 24 pp 493-500.`}</p>
    <p><strong parentName="p">{`Tarlowski, C., McEwin, A.J., Reeves, C.V., and Barton, C.E.,1996.`}</strong>{` Dewarping the composite aeromagnetic anomaly map of Australia using control traverses and base stations.  Geophysics, vol 61, p 696-705.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., Reford, S.W., and Milligan, P.R., 1997.`}</strong>{` Airborne Geophysics: Old Methods, New Images. In Gubbins, A.G., Proceedings of Exploration 97, Fourth Decennial International Conference on Mineral Exploration, p 13-30.`}</p>
    <p><strong parentName="p">{` Reeves, C., Macnab, R., and Maschenkov, S., 1998.`}</strong>{` Compiling all the world’s magnetic anomalies.  EOS, Transactions of the American Geophysical Union, vol 79, p 338.`}</p>
    <p><strong parentName="p">{` Reeves, C.V., 2001.`}</strong>{` The role of airborne geophysical reconnaissance in exploration geoscience.  First Break, 19.9, p 501-508.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2002.`}</strong>{` Geophysical Mapping and International Development.  ASEG Preview, Australian Society of Exploration Geophysicists, December 2002, 101, pp 22-25.`}</p>
    <p><strong parentName="p">{`Reeves, C.V., 2005.`}</strong>{` Aerial Survey, Earth Sciences and Africa’s 21st Century.  ASEG Preview No. 116, pp 24-30.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      